/*------------------------------------------------------- Modal Button */
#applyNow {
  color: #a5843f;
  background-color: #fff;
  border-color: #a5843f;
}
#applyNow:hover {
  background-color: #a5843f;
  border-color: #fff;
  color: #fff;
}
/*------------------------------------------------------- Modal Button */

/*------------------------------------------------------- Parallax Start */
.section {
  min-height: 600px;
  background-attachment: fixed;
  background-size: cover;
  background-position: 50% 50%;
}
.section p {
  font-size: 24px;
  /* color: #000; */
  color: #f5f5f5;
  text-align: center;
  line-height: 60px;
}
.section h1 {
  /* color: #000; */
  color: rgba(255, 255, 255, 0.8);
  font-size: 60px;
  text-align: center;
  padding-top: 60px;
  line-height: 100px;
}

/*------------------------------------------------------- Images for Parallax Sections */
#parallaxHome {
  background-image: url("./components/img/paraFall.jpg");
  /* background-image: url(""); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.jumbotron {
  margin-bottom: 0;
}

.paraBlur {
  backdrop-filter: blur(5px);
}
/*------------------------------------------------------- Parallax End */

/*------------------------------------------------------- Card Flip */
.card {
  width: 22rem;
  height: 22rem;
  perspective: 31.25rem;
  margin: auto;
  padding: 0.25rem;
  border: none;
}

.content {
  /* position: absolute; */
  width: 100%;
  height: 100%;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  transition: transform 1s;
  transform-style: preserve-3d;
}

.card:hover .content {
  transform: rotateY(180deg);
  transition: transform 0.5s;
}

.cardFlipLinks {
  color: #fff;
  font-weight: bolder;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 20px;
  /* font-size: 1em; */
  /* transition: all 500ms; */
}
.cardFlipLinks:hover {
  /* font-size-adjust: 2rem; */
  color: #fff;
  text-decoration: none;
  transform: scale(1.2);
  text-shadow: 2px 2px 2px #000;
  /* color: #000; */
}

.frontFlip {
  position: absolute;
  height: 100%;
  width: 100%;
  background: #fff;
  background-image: url("./components/img/barkBackground.avif");
  line-height: 18.75rem;
  color: #fff;
  text-align: center;
  font-size: 3.75rem;
  border-radius: 0.5rem;
  backface-visibility: hidden;
}

.backFlip {
  position: absolute;
  height: 100%;
  width: 100%;
  background: #03446a;
  background-image: url("./components/img/woodBackground.avif");
  color: #fff;
  transform: rotateY(180deg);
  line-height: 2rem;
  text-align: center;
  font-size: 1rem;
  border-radius: 0.5rem;
  backface-visibility: hidden;
}
/*------------------------------------------------------- Card Flip */

/*------------------------------------------------------- Text Grows or Shrinks on Hover */

.textSizeShift a {
  color: #fff;
  font-weight: bold;
  font-size: 1.5em;
  transition: all 500ms;
  text-decoration: none;
}

.textSizeShift a:hover {
  font-size: 1.8em;
}
.textSizeShiftLibraryBackground {
  background-color: #000;
  height: 2.5rem;
}
/*------------------------------------------------------- Text Grows or Shrinks on Hover */

/*------------------------------------------------------- Hex Gallery */

.hex-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* grid-template-rows: repeat(3, 20rem); */
  grid-gap: 10px;
  margin: 20px;
}

.hex-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20rem;
  height: 20rem;
  background-color: #ddd;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}

.hex-cell img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hex-cell img:hover {
  transform: scale(1.05, 1.05);
  transition: all 0.2s ease;
}

/* .hex-cell:nth-of-type(2) {
	grid-row: span 2;
  } */

/*------------------------------------------------------- Hex Gallery */

/*------------------------------------------------------- Lightbox Gallery */
.gallery {
  display: grid;
  grid-template-rows: repeat(5, 18vh);
  grid-template-columns: repeat(9, 10vw);
  border-radius: 10px;
  padding: 5em;
}
.thumbnails img {
  max-width: 100%;
  height: auto;
  transition: all 0.2s ease-in-out;
}
.thumbnails:hover img {
  opacity: 0.6;
}
.thumbnails img:hover {
  opacity: 1;
}
/*------------------------------------------------------- Lightbox Gallery */

/*------------------------------------------------------- Footer */
.footerBackground {
  background-image: url(./components/img/contactSplash.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}
/*------------------------------------------------------- Footer */

/*------------------------------------------------------- Library */
.libraryTitleBackground {
  background-image: url(./components/img/contactSplash.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}
/*------------------------------------------------------- Library */

/*------------------------------------------------------- NavBar */

.NavbarItems {
  background: rgb(46, 0, 105);
  background-image: url(./components/img/contactSplash.jpg);
  height: 10vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  padding: 0 20px;
  background-repeat: no-repeat;
  background-size: cover;
}

.left-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.right-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.navbar-logo {
  color: #fff;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
}

.co-logo {
  width: 100px;
  height: 100px;
  padding: 0.2rem;
  cursor: pointer;
  border-radius: 2rem;
  margin-right: 10px;
}

@media only screen and (max-width: 768px) {
  .NavbarItems {
    flex-wrap: wrap;
    height: auto;
    padding: 20px;
  }
  .navbar-logo,
  .NavbarItems-btns {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
}

/*------------------------------------------------------- NavBar */

/*------------------------------------------------------- Testimonials */
.testimonials-container {
  background-color: #fff;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem;
  /* border-top: 2px solid #000; */
  /* border-bottom: 2px solid #000; */
}

.line-behind-text {
  border-bottom: 2px solid #28a745;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  line-height: 20px;
  width: 100%;
  height: 11px;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.line-behind-text span {
  padding: 10px;
  background: #28a745;
  border-radius: 5px;
}

.testimonials-bottom-line {
  margin-bottom: 8px;
  border-bottom: 2px solid #28a745;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  line-height: 20px;
  width: 100%;
  height: 11px;
  color: #000;
  text-transform: uppercase;
}

.testimonials-quote,
.testimonials-author {
  color: #000;
}

.arrows-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -45px;
}

.arrow {
  cursor: pointer;
  font-size: 30px;
  padding: 0 10px;
}

.prev-arrow {
  /* transform: rotate(180deg); */
  color: #28a745;
}

.next-arrow {
  /* transform: rotate(180deg) */
  color: #28a745;
}

/*------------------------------------------------------- Testimonials */



/*------------------------------------------------------- Media Queries */
.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
}

@media (max-width: 768px) {
  .container {
    padding: 0 10px;
  }
}

@media (max-width: 480px) {
  h2 {
    font-size: 24px;
  }
  h5 {
    font-size: 18px;
  }
  p {
    font-size: 14px;
  }
}
/*------------------------------------------------------- Media Queries */